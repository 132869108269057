// Navbar.js

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase'; // Import the auth object
import ScrollLink from './subcomponents/ScrollLink'; // Import the ScrollLink component

const Navbar = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [showLoginButton, setShowLoginButton] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
        });

        // Cleanup subscription on unmount
        return () => {
            unsubscribe();
        };
    }, []);

    const handleLoginClick = () => {
        navigate('/login');
        setShowLoginButton(false); // Hide the login button after navigating to the login page
    };

    const handleTitleClick = () => {
        setShowLoginButton(!showLoginButton); // Toggle the visibility of the login button
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            // User is signed out
        } catch (error) {
            // Handle Errors here.
            console.error(error);
        }
    };

    return (
        <nav className="nav-bar">
            <div className="nav-title" onClick={handleTitleClick}>
                Christina Leandra Lopez
            </div>
            {showLoginButton && (!user ? <button className="log-button" onClick={handleLoginClick}>Login</button> : <button className="log-button" onClick={handleSignOut}>Logout</button>)}
            <div className="nav-links">
                <ScrollLink to="/#about-me">About Me</ScrollLink>
                <ScrollLink to="/#my-links">Links</ScrollLink>
                <ScrollLink to="/#my-socials">Socials</ScrollLink>
                <Link to="/donate">Donate</Link>
            </div>
        </nav>
    );
};

export default Navbar;
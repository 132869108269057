import React, { useState } from 'react';
import Navbar from './Navbar'; // Import the Navbar component
import PaymentDropBox from './subcomponents/PaymentDropBox';


import zelle from "./images/DonationPage/Zelle.jpg";
import './css/DonationPage.css'; // Import the CSS file
function DonationPage() {
    //const [paypalOpen, setPayPalOpen] = useState(false);
    const [zelleOpen, setZelleOpen] = useState(false);
    //const [cashAppOpen, setCashAppOpen] = useState(false);
    //const [venmoOpen, setVenmoOpen] = useState(false);
  
/*
    const togglePaypal = () => {
      setPayPalOpen(!paypalOpen);
      setCashAppOpen(false);
      setZelleOpen(false);
      setVenmoOpen(false);
    }
  */
    const toggleZelle = () => {
      setZelleOpen(!zelleOpen);
      //setCashAppOpen(false);
      //setPayPalOpen(false);
      //setVenmoOpen(false);
    }
  /*
    const toggleCashApp = () => {
      setCashAppOpen(!cashAppOpen);
      setZelleOpen(false);
      setPayPalOpen(false);
      setVenmoOpen(false);
    }
  
    const toggleVenmo = () => {
      setVenmoOpen(!venmoOpen);
      setCashAppOpen(false);
      setZelleOpen(false);
      setPayPalOpen(false);
    }
*/
    return (
        <div>
        <Navbar />
    
    
        <div className='payment-portal'>
          <h1 className="contact_header">Donation Options</h1>
                
          
    
          <button className="payment-button" onClick={toggleZelle}>
    <img src={zelle} alt="Zelle Logo" className="payment-logo" />Pay with Zelle
</button>
<div className="paymentdropbox-payment">{zelleOpen ? <PaymentDropBox title="zelle" /> : null}</div>
<br></br>
    
        
          
          <br></br>
        </div>
        </div>
      );
}
  
export default DonationPage;

import React, { useState, useEffect } from 'react';
import { collection, addDoc, deleteDoc, doc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../firebase'; // Import the Firestore database
import { InstagramEmbed, YouTubeEmbed } from 'react-social-media-embed';
import './css/LandingPage.css'; // Import the CSS file
import Navbar from './Navbar'; // Import the Navbar component
import './css/Navbar.css'; // Import the CSS file
import imageSrc1 from './images/imageSrc1.jpg'; // Import the images
import imageSrc2 from './images/imageSrc2.jpg';
import imageSrc3 from './images/imageSrc3.jpg';

import BookCarousel from './subcomponents/BookCarousel';


const LandingPage = ({ user }) => {
    const [newLink, setNewLink] = useState(''); // New link state
    const [links, setLinks] = useState([]); // Links list state
    const [newLinkName, setNewLinkName] = useState(''); // New link name state
    const [editingLink, setEditingLink] = useState(null); // Editing link state

    // Carousel Stuffs
const [panels, setPanels] = useState([]);

// Define the addPanel function
const addPanel = async (file, description) => {
    // Create a storage reference
    const storageRef = ref(storage, `panels/${file.name}`);

    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);

    // Add a new document to the 'panels' collection
    const docRef = await addDoc(collection(db, 'panels'), {
        url: downloadURL,
        description: description
    });

    // Add the new panel to the panels state
    setPanels([...panels, { id: docRef.id, url: downloadURL, description: description }]);
};

// Define the removePanel function
const removePanel = async (id) => {
    // Find the panel
    const panel = panels.find(panel => panel.id === id);

    // Create a storage reference
    const storageRef = ref(storage, `panels/${panel.file.name}`);

    // Delete the file
    await deleteObject(storageRef);

    // Remove the document from the 'panels' collection
    await deleteDoc(doc(db, 'panels', id));

    // Remove the panel from the panels state
    setPanels(panels.filter(panel => panel.id !== id));
};



    // Subscribe to the links collection
    useEffect(() => {
        const unsubscribeFirestore = onSnapshot(query(collection(db, 'links')), (snapshot) => {
            setLinks(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));

            if (editingLink) {
                setNewLinkName(editingLink.name);
                setNewLink(editingLink.url);
            } else {
                setNewLinkName('');
                setNewLink('');
            }
        }, [editingLink]);

        // Cleanup subscription on unmount
        return () => {
            unsubscribeFirestore();
        };
    }, []);

    // Handle the form submission
    const handleAddLink = async (event) => {
        event.preventDefault();
        try {
            await addDoc(collection(db, 'links'), { name: newLinkName, url: newLink });
            setNewLinkName(''); // Clear the input field
            setNewLink(''); // Clear the input field
        } catch (error) {
            console.error(error);
        }
    };

    // Delete a link
    const handleDeleteLink = async (id) => {
        try {
            await deleteDoc(doc(db, 'links', id));
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdateLink = async (id, updatedName, updatedUrl) => {
        try {
            await updateDoc(doc(db, 'links', id), { name: updatedName, url: updatedUrl });
            setEditingLink(null); // Clear the editing state
        } catch (error) {
            console.error(error);
        }
    };

    // Get favicon URL
    const getFaviconUrl = (url) => `https://www.google.com/s2/favicons?domain_url=${url}`;

    return (
        <div>
            <Navbar />
            <div className="grid-container">
                <div className="row" id="about-me">
                    <img src={imageSrc1} alt="About me" />
                    <div className="about-me-content">
                        <h2>About Me</h2>
                        <p>Hi, I'm Christina!</p>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                </div>

                <div className="row" id="book-rec">
                    <div className="book-rec-content">
                        <h2>Book Recommendations</h2>
                        <p>Check out these awesome Reads!</p>
                        {user && (
                            <form onSubmit={event => {
                                event.preventDefault();
                                const fileInput = event.target.elements.file;
                                const descriptionInput = event.target.elements.description;
                                addPanel(fileInput.files[0], descriptionInput.value);
                                fileInput.value = '';
                                descriptionInput.value = '';
                            }}>
                                <input type="file" name="file" required />
                                <input type="text" name="description" placeholder="Description" required />
                                <button type="submit">Add Panel</button>
                            </form>
                        )}
                    </div>
                    <BookCarousel panels={panels} addPanel={addPanel} removePanel={removePanel} user={user} />
                    <div className="book-rec-links">
                    
                    </div>
                </div>

                <div className="row" id="my-links">
                    <img src={imageSrc2} alt="My links" />
                    <div className='affiliate-links'>
                        <h2>My Affiliate Links</h2>
                        {user && (
                            <form onSubmit={editingLink ? () => handleUpdateLink(editingLink.id, newLinkName, newLink) : handleAddLink}>
                                <input type="text" placeholder="Link Name" value={newLinkName} onChange={e => setNewLinkName(e.target.value)} required />
                                <input type="text" placeholder="Link URL" value={newLink} onChange={e => setNewLink(e.target.value)} required />
                                <button type="submit">{editingLink ? 'Update Link' : 'Add Link'}</button>
                            </form>
                        )}
                        <ul>
                            {links.map(link => (
                                <li key={link.id}>
                                    <div>
                                        <img src={getFaviconUrl(link.url)} alt="Favicon" />
                                        <a href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a>
                                    </div>
                                    {user && (
                                        <div className="button-container">
                                            <button className="edit-button" onClick={() => {
                                                setEditingLink(link);
                                                setNewLinkName(link.name);
                                                setNewLink(link.url);
                                            }}>
                                                Edit
                                            </button>
                                            <button className="delete-button" onClick={() => handleDeleteLink(link.id)}>Delete</button>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>



                <div className="row" id="my-socials">
                    <img src={imageSrc3} alt="My socials" />
                    <div className='social-content'>
                        <h2>My Socials</h2>
                        <div className="IGsocials">
                            <InstagramEmbed url="https://www.instagram.com/christinaleandralopez/" />
                        </div>
                        <div className="YTsocials">
                            <YouTubeEmbed url="https://www.youtube.com/embed/dUkH9fq1Qvs" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase'; // Import the auth object
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Login.css';

const Login = () => {
    const [email, setEmail] = useState(''); // Changed from username to email
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // Get the navigate function

    // Modal

    const [showModal, setShowModal] = useState(false);
    const [resetEmail, setResetEmail] = useState('');

    const handleForgotPassword = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleResetEmailChange = (e) => {
        setResetEmail(e.target.value);
    };

    const handleResetPassword = () => {
        sendPasswordResetEmail(auth, resetEmail)
            .then(() => {
                // Password reset email sent!
                alert('Password reset email sent!');
                setShowModal(false);
            })
            .catch((error) => {
                // An error occurred
                console.error(error);
            });
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email || !email.includes('@')) {
            console.error('Please enter a valid email address');
            return;
        }
        try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          // User is signed in
          navigate('/'); // Navigate to the main page
        } catch (error) {
          // Handle Errors here.
          console.error(error);
        }
    };


    return (
        <div className="login-container">
            <button className="back-button" onClick={() => navigate('/')}>Back to Main Page</button>
            
            <h2>Login</h2>
            <form className="login-form" onSubmit={handleSubmit}>
                <input type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
                <br />
                <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                <input type="submit" value="Submit" />
                <button type="button" onClick={handleForgotPassword}>Forgot Password</button>
            </form>
            <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input type="email" placeholder="Email" value={resetEmail} onChange={handleResetEmailChange} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleResetPassword}>
                    Reset Password
                </Button>
            </Modal.Footer>
        </Modal>
            
        </div>
    );

};
export default Login;
import React, { useState } from 'react';

//import paypal from '../images/paypal.png';
import zelle from '../images/DonationPage/Zelle.jpg';
//import cashapp from '../images/cash_app.jpg';
//import venmo from '../images/venmo.png';
//import square from '../images/square.png'



function PaymentDropBox({title}) {
  return (
    <div className='paymentdropbox-payment-portals'>
      {title === 'zelle' ? <img src={zelle} class="zelleImage" ></img> : null}

    </div>
  );
}

export default PaymentDropBox;
import { Link, useLocation } from 'react-router-dom';

const ScrollLink = ({ to, children }) => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    if (isHomePage) {
        return <a href={to}>{children}</a>;
    } else {
        return <Link to={to}>{children}</Link>;
    }
};

export default ScrollLink;
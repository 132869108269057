// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth } from './firebase';
import LandingPage from './Components/LandingPage';
import Login from './Components/Login'; // Import Login
import DonationPage from './Components/DonationPage';

function App() {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage user={user} />} />
        <Route path="/login" element={<Login user={user} />} />
        <Route path="/donate" element={<DonationPage user={user} />} />
      </Routes>
    </Router>
  );
}

export default App;
import { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, addDoc, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import Flicking from '@egjs/react-flicking';
import "@egjs/react-flicking/dist/flicking.css";
import './BookCarousel.css';
import { db, storage } from '../../firebase';

export default ({user}) => {
  const [panels, setPanels] = useState([]);

  useEffect(() => {
    const fetchPanels = async () => {
      const querySnapshot = await getDocs(collection(db, 'panels'));
      const panels = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPanels(panels);
    };

    fetchPanels();
  }, []);

  const addPanel = async (file, description) => {
    const storageRef = ref(storage, file.name);
    await uploadBytesResumable(storageRef, file);
    const url = await getDownloadURL(storageRef);
  
    const docRef = await addDoc(collection(db, 'panels'), { url, description });
    const docSnapshot = await getDoc(docRef);
    const panel = { id: docSnapshot.id, ...docSnapshot.data() };
  
    setPanels(prevPanels => [...prevPanels, panel]);
};

const removePanel = async id => {
  const panel = panels.find(panel => panel.id === id);
  const fileRef = ref(storage, panel.url);
  await deleteObject(fileRef);

  await deleteDoc(doc(db, 'panels', id));
  setPanels(prevPanels => prevPanels.filter(panel => panel.id !== id));
};

  return (
<Flicking align="prev" circular={true} onMoveEnd={e => console.log(e)}>
  {panels.map(panel => (
    <div key={panel.id} className="panel">
      <img 
        src={panel.url} 
        alt={panel.description} 
        onLoad={() => console.log('Image loaded')} 
        onError={(e) => console.log('Image error', e)}
      />
      <div className="description">{panel.description}</div>
      {user && <button onClick={() => removePanel(panel.id)}>Delete</button>}
    </div>
  ))}
</Flicking>
  );
};